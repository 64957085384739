import React from 'react';

import I from '../types.ts';

const renderPreview: I['renderPreview'] = function () {
    const { prize } = this.props;

    return (
        <div className="prize__preview _FULL_W _COL _COL_H_CENTER">
            <div className="prize__previewInner">
                <img src={prize.thumb} alt="" className="prize__previewImage" />
            </div>
            {/* <div className="prize__previewButton _CLICK">
                <span>Загрузить данные</span>
            </div> */}
        </div>
    );
};

export default renderPreview;
