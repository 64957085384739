import setAsyncState from '@functions/setAsyncState.ts';

import I from '../types.ts';

const checkChangeId: I['checkChangeId'] = async function () {
    const { levels } = this.props;
    const id = levels[1];

    if (id && this.id !== id) {
        this.id = id;

        await setAsyncState.call(this, { id });
    }
};

export default checkChangeId;
