import getAuth from '@functions/getAuth.ts';
import AxiosInst from '@functions/initAxios.ts';
import setAsyncState from '@functions/setAsyncState.ts';

import I from '../types.ts';

const sendForm: I['sendForm'] = async function (again) {
    const { code } = this.state;
    const { login, isConfirm } = this.props;

    await setAsyncState.call(this, { loadingKey: again ? 'again' : 'send' });

    try {
        const response = await AxiosInst.post<{}, ResponseT>('/Login', {
            login,
            ...(again === true ? {} : { password: code, isCode: true }),
            ...(isConfirm ? { confirmEmail: true } : {}),
        });

        if (response.result === 'OK') {
            await getAuth();
        }
    } catch (e) {
        const error = e as ResponseErrorT;

        await setAsyncState.call(this, { error: error?.errorText });
    }

    await setAsyncState.call(this, { loadingKey: undefined });
};

export default sendForm;
