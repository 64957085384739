const rules = [
    {
        image: 'Step-5ka-1.png',
        title: 'РЕГИСТРИРУЙ ЧЕКИ',
        content: 'с Добрый® из «Пятёрочки» и\xa0из Доставки «Пятёрочки»',
    },
    {
        image: 'Step-5ka-2.png',
        title: 'УЧАСТВУЙ',
        content: 'в розыгрыше Главных и\xa0еженедельных призов',
    },
    {
        image: 'Step-5ka-3.png',
        title: 'ИГРАЙ',
        content: 'и получай дополнительные шансы в розыгрыше и другие призы',
    },
] as const;

export default rules;
