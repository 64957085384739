const cards = [
    {
        title: 'Покупай',
        content:
            'напитки Добрый® и\xa0регистрируй коды на\xa0сайте или в\xa0<a href="#">чат-боте</a>',
        image: 'Step1.png',
    },
    {
        title: 'Копи баллы',
        content: '1 код - 1 балл, приглашай друзей - получай дополнительные баллы',
        image: 'Step2.png',
    },
    {
        title: 'Обменивай баллы',
        content: 'на гарантированные призы',
        image: 'Step3.png',
    },
    {
        title: 'Участвуй в розыгрышах',
        content: 'мгновенных и\xa0еженедельных',
        image: 'Step4.png',
    },
    {
        title: 'ЗАходи в\xa0шатры',
        content: 'Играй, попадай в\xa0ТОП-500 игроков недели и\xa0участвуй в\xa0розыгрыше призов',
        image: 'Step5.png',
    },
];

export default cards;
