const prizes = [
    {
        image: 'location-game-03.svg',
        title: 'Вперед к сцене',
    },
    {
        image: 'location-game-02.svg',
        title: 'В свете софитов',
    },
    {
        image: 'location-game-01.svg',
        title: 'Лови Вайб',
    },
    {
        image: 'location-game-04.svg',
        title: 'Найди мэтч',
    },
] as const;

export default prizes;
