const socials = {
    vk: {
        logo: 'vk-logo.svg',
    },
    mail: {
        logo: 'ma-logo.svg',
    },
    ya: {
        logo: 'ya-logo.svg',
    },
    ok: {
        logo: 'ok-logo.svg',
    },
} as const;

export default socials;
