import React from 'react';

import I from '../types.ts';

const renderMainPrizeDecors: I['renderMainPrizeDecors'] = function () {
    return (
        <>
            <div className="indexAbout__mainPrizeDecor _1"></div>
            <div className="indexAbout__mainPrizeDecor _2"></div>
            <div className="indexAbout__mainPrizeDecor _3"></div>
            <div className="indexAbout__mainPrizeDecor _4"></div>
            <div className="indexAbout__mainPrizeDecor _5"></div>
            <div className="indexAbout__mainPrizeDecor _6"></div>
        </>
    );
};

export default renderMainPrizeDecors;
