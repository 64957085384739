import React from 'react';

import changePage from '@functions/changePage.ts';

import I from '../types.ts';

const renderHead: I['renderHead'] = function () {
    const { prize } = this.props;

    return (
        <div className="prize__head _COL _FULL_W">
            {prize.status === 'DOWNLOAD' ? (
                <a href={prize.url} target="_blank" className="prize__status" rel="noreferrer">
                    {prize.statusTitle}
                </a>
            ) : prize.status === 'DATA_NEEDED' ? (
                <div
                    className="prize__status _CLICK"
                    onClick={() => {
                        changePage({ pageName: 'full-anket' });
                    }}
                >
                    {prize.statusTitle}
                </div>
            ) : (
                <div className="prize__status">{prize.statusTitle}</div>
            )}

            {prize.count && (
                <div className="prize__count _COL _COL_H_CENTER">
                    <div className="prize__countInner _COL _COL_CENTER">{prize.count}</div>
                    <div className="prize__countSupport">шт</div>
                </div>
            )}
        </div>
    );
};

export default renderHead;
