import React from 'react';

import I from '../types.ts';

const renderDecors: I['renderDecors'] = function (isInner) {
    return (
        <div className="innerPage__decors">
            {isInner ? (
                <>
                    <div className="innerPage__line _2 _inner _ROW _ROW_V_CENTER">
                        {Array(3)
                            .fill({})
                            .map((item, key) => (
                                <img
                                    src={require('@media/app-back-line.svg').default}
                                    alt=""
                                    className="innerPage__lineItem"
                                    key={key}
                                />
                            ))}
                    </div>
                </>
            ) : (
                <>
                    <div className="innerPage__line _1 _ROW _ROW_V_CENTER">
                        {Array(3)
                            .fill({})
                            .map((item, key) => (
                                <img
                                    src={require('@media/app-back-line.svg').default}
                                    alt=""
                                    className="innerPage__lineItem"
                                    key={key}
                                />
                            ))}
                    </div>
                    <div className="innerPage__line _2 _ROW _ROW_V_CENTER">
                        {Array(3)
                            .fill({})
                            .map((item, key) => (
                                <img
                                    src={require('@media/app-back-line.svg').default}
                                    alt=""
                                    className="innerPage__lineItem"
                                    key={key}
                                />
                            ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default renderDecors;
