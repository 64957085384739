import React from 'react';

import PrizeI from './types.ts';

import renderFoot from './renders/renderFoot.tsx';
import renderHead from './renders/renderHead.tsx';
import renderPreview from './renders/renderPreview.tsx';

class Prize extends React.Component<PrizeI['props'], PrizeI['state']> implements PrizeI {
    parent: PrizeI['parent'];

    constructor(props: PrizeI['props']) {
        super(props);
        this.state = {};
        this.parent = React.createRef();
    }

    renderHead = renderHead;
    renderPreview = renderPreview;
    renderFoot = renderFoot;

    render() {
        const { prize } = this.props;

        return (
            <div ref={this.parent} className={`prize _FULL _${prize.status}`}>
                {this.renderHead()}
                {this.renderPreview()}
                {this.renderFoot()}
            </div>
        );
    }
}

export default Prize;
